body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.bar {
  width: 100%;
  height: 20px;
  margin: 10px 0;
  background-color: #ddd;
  position: relative;
}

.fill {
  height: 100%;
  background-color: #76c7c0;
  position: absolute;
}

.log {
  margin-top: 20px;
}

button {
  margin-right: 10px;
  margin-bottom: 10px;
}